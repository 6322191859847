import styled from 'styled-components'
import { flexCenter, label, labelHover, modifySize } from '../mixins'

export const Tags = styled.div`
  ${({ theme, useSmallTags }) => `
      ${flexCenter()}
        a {
            ${label({ theme }, theme['main-color'], theme['white'])}
            &:hover {
            cursor: pointer;
            ${labelHover({ theme })}
            }
            ${
              useSmallTags
                ? `font-size: ${modifySize(theme['font-size'], 0.75)};`
                : `font-size: ${modifySize(theme['font-size'], 1.0)};`
            }
            

      }
    `};
`
