import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import TagList from './tag-list'
import Utils from '../utils'
import { StyledCoursesList, StyledCard } from '../style/components/course-list'

const PostList = ({ posts }) => (
  <StyledCoursesList>
    {posts.map(({ node }, index) => {
      const { title, tags, cover, excerpt } = node.frontmatter
      return (
        <div key={index}>
          <StyledCard className="styled-card">
            {node.frontmatter.path ? (
              <Link to={Utils.resolvePageUrl(node.frontmatter.path)}>
                <Img
                  fluid={cover.childImageSharp.fluid}
                  title={excerpt}
                  alt={title}
                />
              </Link>
            ) : (
              <Img
                fluid={cover.childImageSharp.fluid}
                title={excerpt}
                alt={title}
              />
            )}
            <p>{excerpt}</p>
            <TagList tags={tags} useSmallTags={true} />
          </StyledCard>
        </div>
      )
    })}
  </StyledCoursesList>
)

PostList.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          title: PropTypes.string.isRequired,
          date: PropTypes.string,
          path: PropTypes.string.isRequired,
          tags: PropTypes.arrayOf(PropTypes.string).isRequired,
          cover: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.object.isRequired,
            }).isRequired,
          }).isRequired,
        }),
      }),
    })
  ),
}

export default PostList
