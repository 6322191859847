import styled from "styled-components"
import { flexCenter, modifySize } from "../mixins"

export const Heading = styled.div`
  ${({ theme }) => `
      ${flexCenter()}
        margin: auto;
        h1 {
            margin: ${theme["margin-zero"]};
            font-family: 'CustomBoldFont', sans-serif;
        }
        @media ${theme["mobile"]} {
            flex-direction: column-reverse;
        }
    `};
`

export const Cover = styled.div`
  ${({ theme }) => `
    width: 8%;
    margin: ${theme["margin-medium"]} ${theme["margin-medium"]} ${
    theme["margin-medium"]
  } ${modifySize(theme["margin-medium"], 2)};
    @media ${theme["mobile"]} {
        width: 25%;
        margin: ${theme["margin-small"]};
    }
    `};
`
