/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
/* App imports */
import { Tags } from '../style/components/tag-list'
import Config from '../../config'
import Utils from '../utils'

const TagList = ({ tags, useSmallTags = false }) => (
  <Tags useSmallTags={useSmallTags}>
    {tags
      .filter((tag, index) => index === tags.indexOf(tag)) // Remove duplicate values
      .sort()
      .map(tag => (
        <Link to={Utils.resolvePageUrl(Config.pages.tag, tag)} key={tag}>
          {Config.tags[tag].name || Utils.capitalize(tag)}
        </Link>
      ))}
  </Tags>
)

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default TagList
