import styled from 'styled-components'

export const StyledCoursesList = styled.div`
  ${({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;


    span.new {
      background-color: ${theme['new-label']};
      color: white;
      padding: ${theme['padding-small']};
      border-radius: 50%;
      position: absolute;
      left: -8%;
      top: -8%;
      z-index: 2;
    }

    div.app {
      background-color: ${theme['main-color']};
      color: white;
      padding: ${theme['padding-small']};
      margin-bottom: 0px;
    }
    div.course {
      background-color: ${theme['main-color']};
      color: white;
      padding: ${theme['padding-small']};
      margin-bottom: 0px;
    }


    .inner-card{
      position: relative;
    }



  div{
      justify-content: center;
      max-width: 400px;
      flex-basis: 50%;
  }
  
  .styled-card {
    &:hover {
        transform: scale(1.05) !important;
      }
  }

  @media ${theme['tablet']} {
    div{
        flex-basis: 100%;

    }
  }
  @media ${theme['mobile']} {
    div{
        flex-basis: 100%;
    }
  }
`}
`

export const StyledCard = styled.div`
  ${({ theme }) => `
      margin: ${theme['margin-medium']};
      box-shadow: ${theme['box-shadow']};
      background-color: white;
      text-align: center;
      // height: 500px;
      padding-bottom: ${theme['padding-small']};

      img {
        width: 100%;
      }

      p, h3{
        margin: ${theme['margin-small']} ${theme['margin-medium']};
      }
      p{
        margin-bottom: ${theme['margin-medium']};
      }
      h3{
        margin-top: ${theme['margin-medium']};
      }
`}
`
